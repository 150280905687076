<script>
import { LoginByPassword } from "@/models/actions/operations.gql";
import { required, email } from "vuelidate/lib/validators";
import { validateErrors } from "./validationErrors.js";
import { EMPTY_FIELD, PASSWORD, DEFAULT_FALLBACK, getErrorMessage, fallbackErrorMessage } from "@/backendException.js";
import FormField from "./FormField.vue";
import FormInput from "./FormInput.vue";

export default {
  name: "LoginForm",
  components: { FormField, FormInput },
  props: {
    reason: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      email: "",
      password: "",
      announcementSnackbar: null,
      formErrorToast: null,
      validationReasonMap: new Map([
        [EMPTY_FIELD, "Ensure all fields are filled out."],
        [PASSWORD, "Your email or password was incorrect. Please try again."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      executionReasonMap: new Map([])
    };
  },
  mounted() {
    this.getMountedSnackbarMessage();
  },
  beforeDestroy() {
    this.clearAlerts();
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    validateErrors,
    getMountedSnackbarMessage() {
      if (this.reason) {
        if (this.reason === "Expired") {
          this.formErrorToast = this.$buefy.snackbar.open({
            queue: false,
            message: "Your session has timed out for security reasons. Please log in again to continue.",
            position: "is-top",
            type: "is-danger",
            indefinite: true
          });
        } else {
          this.formErrorToast = this.$buefy.snackbar.open({
            queue: false,
            message: "There was a problem refreshing your access token. Please log in again to continue.",
            position: "is-top",
            type: "is-danger",
            indefinite: true
          });
        }
      } else {
        this.announcementSnackbar = this.$buefy.snackbar.open({
          queue: false,
          message: "We've made enhancements to our login system that require you to reset your password. If you haven't already done so, please reset it now.",
          position: "is-top",
          type: "is-warning",
          actionText: null,
          indefinite: true
        });
      }
    },
    async loginByPassword() {
      try {
        const response = await this.$apollo.query({
          query: LoginByPassword,
          variables: {
            email: this.email,
            password: this.password
          }
        });
        const { accessToken, refreshToken, user } = response.data.result.value || {};
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          this.closeAnnouncementSnackbar();
          this.formErrorToast = this.$buefy.toast.open({
            message: getErrorMessage(errors, this.validationReasonMap, this.executionReasonMap),
            position: "is-top",
            type: "is-danger"
          });
        } else {
          if (this.$route.query.redirectUrl) {
            this.$auth.refreshAccess({ accessToken, refreshToken, user, redirectLocation: this.$route.query.redirectUrl });
          } else {
            this.$auth.refreshAccess({ accessToken, refreshToken, user, redirectLocation: { name: "home" } });
          }
        }
      } catch (error) {
        this.closeAnnouncementSnackbar();
        this.formErrorToast = this.$buefy.toast.open({
          message: fallbackErrorMessage,
          type: "is-danger"
        });
      }
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.closeAnnouncementSnackbar();
        this.formErrorToast = this.$buefy.toast.open({
          message: "Please enter your email and password to log in.",
          type: "is-danger"
        });
      } else {
        this.loginByPassword();
      }
    },
    closeAnnouncementSnackbar() {
      if (this.announcementSnackbar) {
        this.announcementSnackbar.close();
        this.announcementSnackbar = null;
      }
    },
    clearAlerts() {
      if (this.announcementSnackbar) {
        this.announcementSnackbar.close();
      }
      if (this.formErrorToast) {
        this.formErrorToast.close();
      }
    }
  }
};
</script>

<template>
  <form @submit.prevent="submitForm">
    <FormField name="email" label="Email:" :validation="$v.email" :hide-label="true" :error-message="validateErrors($v.email, 'Email')">
      <FormInput v-model.trim="$v.email.$model" placeholder="Email" type="email" />
    </FormField>
    <FormField name="password" label="Password:" :validation="$v.password" :hide-label="true" :error-message="validateErrors($v.password, 'Password')">
      <FormInput v-model.trim="$v.password.$model" placeholder="Password" type="password" />
    </FormField>
    <button type="submit">Log In</button>
  </form>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";

form {
  display: flex;
  flex-direction: column;
  margin: 2.5rem;
  gap: 1.25rem;
  button {
    background-color: white;
    border: 1px solid $border;
    border-radius: 2px;
    color: $blue;
    font-family: $family-secondary;
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    text-align: center;
    padding: 0.75rem 1rem;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: darken(white, 5%);
    }
  }
}
</style>
