<script>
import LoginForm from "./LoginForm.vue";
import RequestPasswordResetForm from "./RequestPasswordResetForm.vue";
import VerificationForm from "./VerificationForm.vue";

export default {
  name: "Login",
  components: { LoginForm, RequestPasswordResetForm, VerificationForm },
  props: {
    reason: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      forgotPassword: false,
      passwordResetRequested: false,
      submittedEmail: null
    };
  },
  methods: {
    toPasswordReset() {
      this.forgotPassword = true;
    },
    toLogin() {
      this.forgotPassword = false;
    },
    handleEmailSubmission(email) {
      this.submittedEmail = email;
      this.passwordResetRequested = true;
    },
    getSignupRoute() {
      const routeData = { name: "signup" };
      if (this.$route.query.redirectUrl) {
        routeData.query = { redirectUrl: this.$route.query.redirectUrl };
      }
      return routeData;
    }
  }
};
</script>

<template>
  <section>
    <div class="main-container">
      <div class="form-container">
        <LoginForm v-if="!forgotPassword" :reason="reason" />
        <RequestPasswordResetForm
          v-else-if="forgotPassword && !passwordResetRequested"
          @emailSubmitted="handleEmailSubmission"
        />
        <VerificationForm
          v-else
          :email="submittedEmail"
          :instruction="'Reset your password by clicking the verification link we\'ve sent to your email. Or, enter the code from your verification email below.'"
          :submit-button-text="'Verify Code'"
          :redirect-location="'reset-password'"
          :success-snackbar-message="`An email has been sent to ${this.submittedEmail} with a link to reset your password.`"
          :form-error-toast-message="'Please enter the verification code you were sent to reset your password.'"
        />
        <div class="extra-links">
          <a v-if="!forgotPassword" @click="toPasswordReset">Reset Password</a>
          <a v-else @click="toLogin">Back to Login</a>
          <p>Don't have an account? <router-link :to="getSignupRoute()">Sign Up</router-link></p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";
section {
  display: flex;
  justify-content: center;
  width: 100vw;
  .main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1700px;
    gap: 1rem;
    .form-container {
      background-color: white;
      border: 1px solid #dcdee9;
      width: 100vw;
      margin: 7rem ;
      .extra-links {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        p {
          color: $grey700;
          font-size: 0.875rem;
        }
        a {
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }
    @include from($tablet) {
      margin: 2rem;
      gap: 2rem;
      .form-container {
        width: 400px;
      }
    }
  }
}
</style>
